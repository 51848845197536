import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { compareDesc } from "date-fns"

import { Layout, GridContainer } from "../components/common"
import media from "../styles/media"
import SEO from "../components/common/SEO"
import { isNotPlaceholder } from "../utils/common/content"

const Container = styled(GridContainer)`
  display: grid;
  margin-top: 67px;
  p {
    word-break: break-all;
    margin: 0 auto;
  }

  div {
    ${media.tablet`
      margin-bottom: 20px;
    `}
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }

  img {
    width: 100%;
  }
`

const ContentCard = styled.div`
  min-width: 100%;
  grid-column-end: span 2;

  ${media.tablet`
    grid-column-end: span 6;
  `}
`

const Content = styled.div`
  white-space: normal !important;
`

const Title = styled.h3`
  font-size: 16px;
  font-weight: normal;
  font-family: StanleyRegular, serif;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: -0.27px;

  ${media.tablet`
    margin: 0 auto;
    padding-right: 20px;
    font-size: 24px;
  `}
`

const ArticleDate = styled.p`
  font-size: 12px;
  color: ${props => props.theme.lightGrey};
  letter-spacing: 1.33px;
  line-height: 20px;
  text-transform: uppercase;
  padding-top: 5px;

  ${media.tablet`
    padding:0;
    font-size: 16px;
    line-height: 24px;
  `}
`
const ButtonContainer = styled(GridContainer)`
  display: grid;
  justify-items: center;
  margin-bottom: 50px;
  margin-top: 50px;

  ${media.tablet`
    margin-bottom: 100px;
  `}
`
const ButtonDiv = styled.div`
  grid-column: 2 / span 2;

  ${media.tablet`
    grid-column: 6 /span 2;
  `}
`
const Button = styled.button`
  width: 112px;
  height: 51px;
  border: 2px solid black;
  font-family: StanleyPoster, serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
`

class News extends Component {
  state = {
    numToShow: 8,
  }

  render() {
    const newsListingContent = this.props.data.allContentfulNewsArticle.edges
      .filter(isNotPlaceholder)
      .sort((first, second) => {
        return compareDesc(
          new Date(first.node.date),
          new Date(second.node.date)
        )
      })

    const { numToShow } = this.state
    let articlesToShow = newsListingContent.slice(0, numToShow)
    const pageSize = 8
    const reachedEnd = articlesToShow.length === newsListingContent.length

    const imageUrl =
      newsListingContent.newsPageMetaContent &&
      newsListingContent.newsPageMetaContent.metaImage
        ? newsListingContent.newsPageMetaContent.metaImage.file.url
        : undefined

    const twitterImageUrl =
      newsListingContent.newsPageMetaContent &&
      newsListingContent.newsPageMetaContent.metaImageTwitter
        ? newsListingContent.newsPageMetaContent.metaImage.file.url
        : undefined

    return (
      <Layout headerTheme="dark">
        <SEO
          title="Columbia Artists - News"
          path="/news"
          description={
            newsListingContent.newsPageMetaContent
              ? newsListingContent.newsPageMetaContent.metaDescription
                  .metaDescription
              : undefined
          }
          imageUrl={imageUrl && "https:" + imageUrl}
          twitterImageUrl={twitterImageUrl && "https" + twitterImageUrl}
        />
        <Container>
          {articlesToShow.map((news, i) => {
            const title = news.node.title
            const date = news.node.date
            const articleLink = `/news/${news.node.slug}`

            return (
              <ContentCard key={i}>
                <Link to={articleLink}>
                  <Img alt={title} fluid={news.node.coverImage.fluid} />
                </Link>
                <Content>
                  <Link to={articleLink}>
                    <ArticleDate>{date}</ArticleDate>
                    <Title>{title}</Title>
                  </Link>
                </Content>
              </ContentCard>
            )
          })}
        </Container>
        <ButtonContainer>
          <ButtonDiv>
            {!reachedEnd && (
              <Button
                onClick={() =>
                  this.setState({ numToShow: numToShow + pageSize })
                }
              >
                <span>More</span>
              </Button>
            )}
          </ButtonDiv>
        </ButtonContainer>
      </Layout>
    )
  }
}

export default News

export const pageQuery = graphql`
  {
    allContentfulNewsArticle {
      edges {
        node {
          newsPageMetaContent {
            metaDescription {
              metaDescription
            }
            metaImageTwitter {
              file {
                url
              }
            }
            metaImage {
              file {
                url
              }
            }
          }
          title
          slug
          date
          body {
            childMarkdownRemark {
              html
            }
          }
          coverImage {
            fluid(maxWidth: 900, maxHeight: 550, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            file {
              url
            }
          }
        }
      }
    }
  }
`
